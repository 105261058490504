import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors, { media, telNumber } from '../utils/variables';
import Icon from './widgets/Icon';
import Logo from '../assets/logoBlanc.png';


const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(216, 232, 242, 0.1);
  opacity: ${(props) => (props.showFooter ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0rem 5rem;
  ${media.tabletteLandscape`
    padding: 0rem 2rem;
  `}
  ${media.tabletteVerticale`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0rem;
    gap: 2rem;


`}
  `;
  
  const FooterBlock = styled.div`
  background: black;
  display: flex;
  width: 350px;
  flex-direction: column;
  color: #ffffff;
  h5{
    font-size: 1.2rem;
    margin: 1rem 0;
    text-align: center;
  }
  p{
    font-size: 1rem;
    margin-top: 0.5rem;
    text-align: center;
  }
  span{
    font-weight: 700;
  }
  a{
    text-decoration: underline;
    margin-top: 0.4rem;
    text-align: center;
  }
  strong{
    ${media.mobile`
    display: none;
  `}
  }
`;

const TextWithIconContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  i {
    font-size: 1rem;
    margin-top: 5px;
    align-items: center;


  }
`;


const Bandeau = styled.div`
  background: black;
  color: ${colors.white};
  text-align: center;
  padding: 10px;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 5rem;
  gap: 1rem;
  a{
    text-decoration: underline;
  }
  ${media.tabletteVerticale`
  flex-direction: column;
  `}
  ${media.mobile`
  margin: 0;
  `}

`;
const StyledTrait = styled.div`
  width: 30%;
  border-top: 1px solid rgba(216, 232, 242, 0.1);
  margin-right: 5rem;
  ${media.tabletteVerticale`
   width: 100%;
   margin: 0;
`}
`;

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
  return (

    <StyledFooter showFooter={showFooter}>
      <MainContainer>
        <FooterBlock>
          <h5>Infos pratiques:</h5>
          <TextWithIconContainer>
            <Icon logo={'fa-solid fa-home'} hoverable={false} pointer={false}/>
            <p><strong>Adresse :</strong> 4 rue des linots, 13004 Marseille</p>
          </TextWithIconContainer>
          <TextWithIconContainer>
           <Icon logo={'fa-solid fa-phone'}hoverable={false} pointer={false}/>
            <p><strong>Téléphone :</strong> {telNumber}</p>
          </TextWithIconContainer>
          <TextWithIconContainer>
            <Icon logo={'fa-solid fa-envelope-open-text'}hoverable={false} pointer={false}/>
            <p><strong>Email:</strong> contact@45escalade.com</p>
          </TextWithIconContainer>
        </FooterBlock>
        <FooterBlock>
          <h5>Horaires :</h5>
            <p><span>Semaine :</span> 10h30 - 23h00</p>
            <p><span>Week-end :</span> 10h00 - 22h00</p>
        </FooterBlock>
        <FooterBlock>
          <h5>Liens utiles:</h5>
            <a href="lieu"> Lieu</a>
            <a href="guinguette"> Guinguette</a>
            <a href="tarifs"> Tarifs</a>
            <a href="actus"> Actus</a>
            <a href="localisation"> Nous trouver</a>
            <a href="https://boutique.45escalade.com" target="_blank" rel="noopener noreferrer"> Boutique</a>
        </FooterBlock>
      </MainContainer>
        <StyledTrait />
      <Bandeau>
        <img src={Logo} alt="Logo" style={{ width: '50px', marginRight: '10px' }} />
        <span>&copy; 2024 - Tous droits réservés</span>
        <a href="conditions"> CGV</a>
        <a href="règlements"> Règlement intérieur</a>
      </Bandeau>
    </StyledFooter>
  );
};

export default Footer;
