const colors = {
  darkBlue: '#071E25',
  blue: '#00A9EF',
  lightBlue: '#d8e8f2',
  red: '#ff3300',
  white: '#f2f2f2',
  black: '#333335',
}
export const breakpoints = {
  mobile: '568px',
  tabletteVerticale: '768px',
  tabletteLandscape: '1024px',
  laptop: '1366px',
  desktop: '1680px',
};
export const telNumber = "+33486946520"
export const media = {
  mobile: (styles) => `@media screen and (max-width: ${breakpoints.mobile}) { ${styles} }`,
  tabletteVerticale: (styles) => `@media screen and (max-width: ${breakpoints.tabletteVerticale}) { ${styles} }`,
  tabletteLandscape: (styles) => `@media screen and (max-width: ${breakpoints.tabletteLandscape}) { ${styles} }`,
  laptop: (styles) => `@media screen and (max-width: ${breakpoints.laptop}) { ${styles} }`,
  desktop: (styles) => `@media screen and (max-width: ${breakpoints.desktop}) { ${styles} }`,
  iphoneSE: (styles) => `
    @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      ${styles}
    }
  `,
  iphone13: (styles) => `
    @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
      ${styles}
    }
  `,
};
export default colors


